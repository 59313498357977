<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="file" /> writeups
      </p>
    </header>

    <div class="card-content">
      <div class="content">

        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ data.length }}</strong> Writeups
              </p>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-switch v-model="searchable"><b-icon icon="search"/></b-switch>
            </div>
            <div class="level-item">
              <b-button icon-left="archive" @click="downloadArchive">
                Archive Download
              </b-button>
            </div>
            <div class="level-item">
              <b-button icon-left="upload" @click="uploadCSV">
                CSV Upload
              </b-button>
            </div>
          </div>
    </nav>

    <b-table
        :data="data"
        hoverable
      default-sort="timestamp"
      default-sort-direction="desc">

      <b-table-column field="challenge" label="Challenge" sortable :searchable=searchable  v-slot="props">
        {{ props.row.challenge }}
      </b-table-column>
      <b-table-column field="mail" label="Mail" sortable :searchable=searchable  v-slot="props">
        {{ props.row.mail }}
      </b-table-column>
      <b-table-column field="name" label="Name" sortable :searchable=searchable  v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="surname" label="Surname" sortable :searchable=searchable  v-slot="props">
        {{ props.row.surname }}
      </b-table-column>
      <b-table-column field="nickname" label="Nick" sortable :searchable=searchable  v-slot="props">
        {{ props.row.nickname }}
      </b-table-column>
      <b-table-column field="timestamp" label="Timestamp" sortable v-slot="props">
        {{ props.row.timestamp }}
      </b-table-column>
      <b-table-column label="Actions" width="40" centered v-slot="props">
      <router-link :to="{
        name: 'admin_evaluation',
        params: {
          writeup_id: props.row.id,
        }
      }">
        <b-icon icon="edit"/>
      </router-link>
      </b-table-column>
    </b-table>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

const DownloadArchiveForm = {
  template: `
   <form @submit.prevent="submit">
       <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              <b-icon icon="file" />
              Download Challenge Writeup Archive
            </p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Challenge">
              <b-select placeholder="Please select one" v-model="selected" required>
                <option
                    v-for="chal in challenges"
                    :value="chal.name"
                    :key="chal.name">
                    {{ chal.name }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
                label="Download"
                native-type="submit"
                expanded
                type="is-primary" />
          </footer>
       </div>
   </form>
`,
  data() {
    return {
      challenges: [],
      selected: null,
    };
  },
  methods: {
    async submit() {
      // Create a fake anchor and click on it to trigger download (https://stackoverflow.com/a/49917066)
      const a = document.createElement('a');
      a.href = `/admin/writeups/${this.selected}/archive`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.$emit('close');
    },
  },
  async mounted() {
    try {
      this.challenges = await this.$ctforge.api.get('/challenges/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};

const UploadCSVForm = {
  template: `
   <form @submit.prevent="submit">
       <div class="modal-card" style="width: 60em">
          <header class="modal-card-head">
            <p class="modal-card-title">
              <b-icon icon="file" />
              Upload Challenge Evaluation CSV
            </p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Challenge">
              <b-select placeholder="Please select challenge" v-model="selected" required accept=".csv">
                <option
                    v-for="chal in challenges"
                    :value="chal.name"
                    :key="chal.name">
                    {{ chal.name }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Evaluation CSV">
              <b-upload v-model="file" drag-drop expanded required>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Drop your the CSV here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags mt-2">
                <span v-if="file" class="tag is-primary">
                  {{ file.name }}
                </span>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
                label="Close"
                expanded
                @click="$emit('close')" />
            <b-button
                label="Upload"
                native-type="submit"
                expanded
                type="is-primary" />
          </footer>
       </div>
   </form>
`,
  data() {
    return {
      challenges: [],
      selected: null,
      file: null,
    };
  },
  methods: {
    async submit() {
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        const res = await fetch(`/admin/evaluation/${this.selected}/csvimport`, {
          method: 'POST',
          headers: { // TODO: move into this.$ctforge
            'X-SEC-CTFORGE': 'csrf-protection',
          },
          body: formData,
        });
        const js = await res.json();
        if (res.status < 200 || res.status > 299) throw new Error(js.message);
        this.notify(js.message, 'is-success');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  async mounted() {
    try {
      this.challenges = await this.$ctforge.api.get('/challenges/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};

export default {
  data() {
    return {
      data: [],
      searchable: false,
    };
  },
  methods: {
    downloadArchive() {
      this.$buefy.modal.open({
        parent: this,
        component: DownloadArchiveForm,
        hasModalCard: true,
        trapFocus: false,
      });
    },
    uploadCSV() {
      this.$buefy.modal.open({
        parent: this,
        component: UploadCSVForm,
        hasModalCard: true,
        trapFocus: false,
      });
    },
  },
  async mounted() {
    try {
      this.data = await this.$ctforge.api.get('/admin/writeups/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};
</script>

<style>
</style>
