<template>
  <div>

 <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="clone" /> Plagiarism
      </p>
    </header>

    <div class="card-content">
      <div class="content">

        <nav class="level">
          <div class="level-right">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ data.length }}</strong> Challenges
              </p>
            </div>
          </div>
          <div class="level-right">
             <div class="level-item">
              <b-switch v-model="searchable"><b-icon icon="search"/></b-switch>
            </div>
           </div>
        </nav>

        <b-table
          :data="data"
          hoverable
          default-sort="challenge_id">
          <b-table-column field="challenge_id" label="#" numeric centered width='40' sortable v-slot="props">
            {{ props.row.challenge_id }}
          </b-table-column>
          <b-table-column field="name" label="Name" sortable :searchable=searchable  v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Actions" width="100" centered v-slot="props">
            <a @click="view(props.row.challenge_id)">
              <b-icon icon="eye" /> View
            </a>
          </b-table-column>
        </b-table>

      </div>
    </div>
 </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      searchable: false,
    };
  },
  methods: {
    async view(id) {
      this.$router.push(`/admin/plagiarism/${id}`);
    },
  },
  async mounted() {
    try {
      this.data = await this.$ctforge.api.get('/challenges/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};
</script>

<style>
</style>
