<template>
<AdminChallengeForm :isEditing="isEditing" :challengeName="challengeName" @submitCallback="submit">
  <template v-slot:extras>
    <b-field>
      <b-checkbox v-model="required">Require a writeup</b-checkbox>
    </b-field>
    <b-field label="Writeup Template">
      <b-input type="textarea" v-model="writeupTemplate"></b-input>
    </b-field>
  </template>
</AdminChallengeForm>
</template>

<script>
import AdminChallengeForm from '../../../challenges/components/admin/AdminChallengeForm.vue';

export default {
  props: {
    isEditing: Boolean,
    challengeName: {
      type: String,
      default: 'chal',
      required: false,
    },
  },
  data() {
    return {
      required: true,
      writeupTemplate: null,
    };
  },
  components: {
    AdminChallengeForm,
  },
  methods: {
    async loadRequired() {
      try {
        const resp = await this.$ctforge.api.get(`/challenges/${encodeURIComponent(this.challengeName)}/required`);
        this.required = resp.required;
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async loadTemplate() {
      try {
        const resp = await this.$ctforge.api.get(`/challenges/${encodeURIComponent(this.challengeName)}/template`);
        this.writeupTemplate = resp.writeup_template;
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async submit(id) {
      try {
        await this.$ctforge.api.post('/admin/challenges/require', {
          challenge_id: id,
          required: this.required,
        });
        await this.$ctforge.api.post('/admin/challenges/template', {
          challenge_id: id,
          writeup_template: this.writeupTemplate,
        });
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  async mounted() {
    if (!this.isEditing) { return; }
    await this.loadRequired();
    await this.loadTemplate();
  },
};
</script>

<style>
</style>
