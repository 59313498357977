<template>
<div id="app">
  <Navbar />
<!--<section class="hero">
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title">
       <b-icon class="pr-3" icon="chart-line" /> Scoreboard
      </h1>
    </div>
  </div>
</section>-->
  <div id="main-content" class="container mt-5 pt-5 mb-5 pb-5">
    <router-view />
  </div>
  <footer class="footer mt-5">
    <div class="content has-text-left">
      <p>
        Powered by <a href="https://github.com/ctforge/ctforge/" target="_blank">
          <img style="height:1em" class="pt-1"
               src="https://github.com/secgroup/ctforge/raw/wutctf2.0/ctforge/themes/wutctf2.0/static/img/ctforge_white.png" alt="CTForge">
        </a>
      </p>
    </div>
  </footer>
  <b-button v-if="isAdmin"
            type="is-danger"
            style="position: fixed; right: 60px; bottom: 40px; opacity: 0.8"
            tag="router-link"
            to="/admin"
            icon-right="cog" />
</div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  mounted() {
    this.$ctforge.loadConfig();
    this.$ctforge.loadUserinfo();
    this.$ctforge.checkLogin(); // [TODO] without this line SAML login doesn't work, fix it
  },
  computed: {
    isAdmin() {
      return this.$ctforge.state.loggedin
            && this.$ctforge.state.userinfo.admin;
    },
  },
};
</script>

<style>
#main-content {
    /* this is likely to break */
    min-height: calc(100vh - 15rem);
}
</style>
