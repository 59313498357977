<template>
<div>
  <section class="hero is-small box p-0" v-if="!hideHeader">
    <div class="hero-body">
      <p class="title">
        {{ data.challege_name }} - Writeup
      </p>
      <div class="subtitle">
        By {{ userName }}
        <span class="is-pulled-right">
          <b-icon icon="clock" />
          {{ new Date(data.timestamp).toLocaleString() }}
        </span>
      </div>
    </div>
  </section>

  <div class="columns">
    <div class="column is-three-quarters">

      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="pen-alt" />
            Writeup
          </p>
          <span class="card-header-icon" aria-label="Markdown">
            <b-switch v-model="markdown"><b-icon icon="code"/></b-switch>
          </span>
        </header>
        <div class="card-content">
          <pre class="box" v-if="!markdown"><code>{{ data.writeup }}</code></pre>
          <div class="content" v-else v-html="markdownWriteup"></div>
        </div>
      </div>
    </div>

    <div class="column is-one-quarter">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="folder-open" />
            Files
          </p>
        </header>
        <div class="card-content">
          <b-menu v-if="files.length">
            <b-menu-list>
              <b-menu-item icon="file-alt" :label="f.name" v-for="f in files" :key="f.id"
                           tag="a" :href="'/files/' + f.id + '/download'"></b-menu-item>
            </b-menu-list>
          </b-menu>
          <p class="has-text-centered" v-else>No File.</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DOMPurify from 'dompurify';
import marked from 'marked';

export default {
  name: 'WriteupInfo',
  props: {
    id: { required: true },
    hideHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      data: [],
      files: [],
      selectedFile: null,
      markdown: false,
    };
  },
  computed: {
    userName() {
      return this.$ctforge.state.userinfo.name;
    },
    markdownWriteup() {
      return DOMPurify.sanitize(marked(this.data.writeup));
    },
  },
  methods: {
    onFileSelected(e) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = e.target.files[0];
    },
  },
  async mounted() {
    const loadingComponent = this.$buefy.loading.open({ container: null });
    try {
      this.data = await this.$ctforge.api.get(`/writeups/${this.id}/info`);
      this.files = await this.$ctforge.api.get(`/files/${this.id}/list`);
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
    loadingComponent.close();
  },
};
</script>

<style>
</style>
