<template>
<div>
  <h1 class="pb-5 has-text-weight-bold is-size-3" style="margin-bottom: -2rem">Challenges</h1>

  <div class="box has-text-centered is-size-4 mt-5" v-if="data !== null && !data.length">
    <b-icon icon="frown" class="mr-2" />
    No Challenges
  </div>
  <div class="is-inline-block mr-5 mt-5"  v-for="challenge in data" :key="challenge.name">

    <router-link v-bind:to="'/challenges/' + challenge.name" class="box is-inline-block" style="width:20.9005em"> <!-- TODO: is-one-quarter -->
      <nav class="level">
        <div class="level-left">
          <div class="level-item has-text-centered">
            {{ challenge.points }} pts
          </div>
        </div>
        <div class="level-right">
          <div class="level-item has-text-centered">
            {{ challenge.solvers }} solver(s)
          </div>
        </div>
      </nav>
      <div class="has-text-centered is-size-3 has-text-weight-bold">
        <b-icon icon="check-circle" v-if="challenge.solved" />
        <b-icon icon="eye-slash" class="has-text-danger pr-3" v-if="challenge.active === false" />
        {{ challenge.name }}
      </div>
      <div class="tag is-info has-text-link-light is-block pt-1 mt-4 is-medium block is-static has-text-centered">
        {{ challenge.tags }}
      </div>
    </router-link>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },

  async mounted() {
    const loadingComponent = this.$buefy.loading.open({ container: null });
    try {
      this.data = await this.$ctforge.api.get('/challenges/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
    loadingComponent.close();
  },
};
</script>

<style>
</style>
