<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="th-list" /> Challenges
      </p>
    </header>

    <div class="card-content">
      <div class="content">

        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ data.length }}</strong> Challenges
              </p>
            </div>
          </div>
          <div class="level-right">
            <p class="level-item">
              <b-button
                icon-left="plus"
                @click='redirect'>
                Add Challenge
              </b-button>
            </p>
          </div>
        </nav>

        <b-table
          :data="data"
          hoverable
          default-sort="challenge_id">
          <b-table-column field="challenge_id" label="#" numeric centered width='40' sortable v-slot="props">
            {{ props.row.challenge_id }}
          </b-table-column>
          <b-table-column field="name" label="Name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="points" label="Points" sortable v-slot="props">
            {{ props.row.points }}
          </b-table-column>
          <b-table-column field="parameter" label="Scoring Parameter" sortable v-slot="props">
            {{ props.row.scoring_parameter }}
          </b-table-column>
          <b-table-column field="deadline_end" label="Deadline End" sortable v-slot="props">
            {{ props.row.submission_ending_time}}
          </b-table-column>
          <b-table-column field="active" label="Active" width="40" centered v-slot="props">
            <b-icon icon="check" v-if="props.row.active"/>
            <b-icon icon="times" v-else />
          </b-table-column>
          <b-table-column label="Actions" width="100" centered v-slot="props">
            <router-link :to="{
                name: 'admin_challenges_edit',
                params: { challenge_name: props.row.name }
              }">
              <b-icon class="pr-3" icon="edit"/>
            </router-link>
            <a v-on:click="delete_chal(props.row.challenge_id)">
              <b-icon class="pl-3" icon="trash" />
            </a>
          </b-table-column>

        </b-table>

      </div>
    </div>
  </div>

</div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
    };
  },

  methods: {
    delete_chal(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Challenge',
        message: `Are you sure you want to <b>delete</b> challenge ${id}? This action cannot be undone.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            // TODO assertion error!
            // AssertionError: Dependency rule tried to blank-out primary key column 'challenge_solutions.challenge_id' on instance '<ChallengeSolution at 0x7f0a9ea54cd0>'
            this.$ctforge.api.post('/admin/challenges/delete', { challenge_id: id });
            this.$router.push('/admin/challenges'); // TODO: refresh view, this does not work
            this.notify('Challenge Deleted', 'is-info');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
        },
      });
    },
    async redirect() {
      try {
        this.$router.push({ name: 'admin_challenges_add' });
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  async mounted() {
    try {
      this.data = await this.$ctforge.api.get('/challenges/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};
</script>
<style></style>
