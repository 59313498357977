<template>
<div v-html="content" v-if="!markdown"></div>
<div class="content" v-else v-html="markdownPage()"></div>
</template>

<script>
import marked from 'marked';

export default {
  props: ['route'],
  data() {
    return {
      title: null,
      content: null,
      markdown: false,
    };
  },
  methods: {
    markdownPage() { return marked(this.content); },
    async refresh() {
      try {
        const route = this.route === '/' ? '__index__' : this.route.replace(/^\/*/, '');
        const resp = await this.$ctforge.api.get(`/cms/page/${route}`);
        this.title = resp.title;
        this.content = resp.content;
        this.markdown = resp.markdown;
      } catch (e) {
        if (e.status_code === 404) {
          this.title = 'Page Not Found';
          this.content = "<div class='content has-text-centered'><h2>404 - Page Not Found</h2></div>";
        } else this.notify(e.message, 'is-danger');
      }
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    route() {
      this.refresh();
    },
  },
};
</script>

<style>
</style>
