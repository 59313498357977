<template>
<ChallengeInfo>
  <template v-slot:writeups>

    <div class="columns" v-if="required">
      <div class="column is-two-thirds">

        <div class="card mt-5"  v-if="evaluation.exists">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="star" />
              Your Feedback
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <table class="table is-fullwidth is-bordered">
                <tbody>
                  <tr>
                    <td width="160"><b-icon class="pr-3" icon="clock"/> Time</td> <td>{{ new Date(evaluation.timestamp).toLocaleString() }}</td>
                  </tr>
                  <tr>
                    <td><b-icon class="pr-3" icon="user"/> Grade</td> <td> <b>{{ ratingStr }}</b> </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-5">
                <pre><code>{{ evaluation.feedback }}</code></pre>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-5" v-if="!evaluation.exists">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="paper-plane" />
              Submit a Writeup
            </p>
          </header>
          <div class="card-content">
            <span class="subtitle is-size-6">Writeup submission can be performed until the deadline</span>

            <form @submit.prevent="submit" class="mt-5" v-if="!evaluation.exists">
              <b-field label="Writeup" label-position="on-border">
                <b-input type="textarea" required v-model="writeup" rows="20" placeholder="Your writeup" @input="writeupChanged"></b-input>
              </b-field>

              <b-field label="Additional Files">
                <b-upload v-model="dropFiles" multiple drag-drop expanded>
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>Drop your files here or click to upload</span>
                  </a>
                </b-upload>
              </b-field>

              <div class="tags">
                <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
                  {{file.name}}
                  <!-- TODO <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button> -->
                </span>
              </div>

              <b-button native-type="submit" class="is-primary">Submit</b-button>
            </form>
          </div>
        </div>

      </div>

      <div class="column is-one-third">
        <div class="card mt-5">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="list-ol" />
              Submitted Writeups
            </p>
          </header>
          <div class="card-content">
            <b-menu v-if="writeups.length">
              <b-menu-list>
                <b-menu-item icon="book" :label="new Date(wr.timestamp).toLocaleString()" v-for="wr in writeups" :key="wr.id"
                             tag="router-link" :to="'/writeups/' + wr.id"></b-menu-item>
              </b-menu-list>
            </b-menu>
            <p class="has-text-centered" v-else><b-icon icon="frown" /> You haven't submitted a writeup yet</p>
          </div>
        </div>
      </div>
    </div>

  </template>
</ChallengeInfo>
</template>

<script>
import ChallengeInfo from '../../challenges/components/ChallengeInfo.vue';

export default {
  components: {
    ChallengeInfo,
  },
  data() {
    return {
      name: this.$route.params.challenge_name,
      writeup: '',
      writeups: [],
      required: [],
      dropFiles: [],
      evaluation: {
        exists: false,
        rating: 0.0,
        feedback: '',
      },
    };
  },
  methods: {
    async submit() {
      try {
        const res = await this.$ctforge.api.post('/writeups/submit', {
          challege_name: this.name,
          writeup: this.writeup,
        });
        const { id } = res;
        await Promise.all(this.dropFiles.map(async (file) => {
          await this.uploadFile(id, file);
        }));
        this.notify('Writeup Submitted!');
        this.updateView();
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    writeupChanged() {
      localStorage.setItem(this.localKey, this.writeup);
    },
    async uploadFile(wid, file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const res = await fetch(`/files/${wid}/upload`, {
          method: 'POST',
          headers: { // TODO: move into this.$ctforge
            'X-SEC-CTFORGE': 'csrf-protection',
          },
          body: formData,
        });
        const js = await res.json();
        if (res.status >= 200 && res.status <= 299) {
          return js;
        }
        throw new Error(js.message);
      } catch (e) {
        this.notify(e.message, 'is-danger');
        throw e;
      }
    },
    async updateView() {
      const loadingComponent = this.$buefy.loading.open({ container: null });
      try {
        let resp = await this.$ctforge.api.get(`/challenges/${this.name}/required`);
        this.required = resp.required;

        const fromLocal = localStorage.getItem(this.localKey);
        if (fromLocal) {
          this.writeup = fromLocal;
        } else {
          resp = await this.$ctforge.api.get(`/challenges/${this.name}/template`);
          this.writeup = resp.writeup_template;
        }

        this.writeups = await this.$ctforge.api.get(`/writeups/${this.name}/list`);
        this.writeups.sort((fst, snd) => fst.timestamp < snd.timestamp);

        this.evaluation = await this.$ctforge.api.get(`/evaluation/${this.name}`);
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      loadingComponent.close();
    },
  },
  computed: {
    localKey() {
      return `writeup$${this.name}$$writeup`;
    },
    ratingStr() {
      const r = this.evaluation.rating;
      if (r === null) return 'No Rating';
      // Use + to cast to float (gets rid of unnecessary trailing zeros)
      return +r;
    },
  },
  async mounted() {
    this.updateView();
  },
};
</script>

<style>
</style>
