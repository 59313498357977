import AdminPlagiarismIndex from './components/AdminPlagiarismIndex.vue';
import AdminPlagiarism from './components/AdminPlagiarism.vue';

export default {

  install(vue) {
    // Admin interface
    vue.$router.addChildrenRoutes('/admin', [
      {
        path: 'plagiarism',
        component: AdminPlagiarismIndex,
      },
      {
        path: '/admin/plagiarism/:challenge_id',
        component: AdminPlagiarism,
      },
    ]);
    vue.$ctforge.addAdminLinks([
      { name: 'Plagiarism', path: '/admin/plagiarism', icon: 'clone' },
    ]);
  },
};
