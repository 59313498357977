<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item tag="router-link" to="/" v-html="header">
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="item.path"
                     v-for="item in links" :key="item.name">
        <b-icon class="pr-3" :icon="item.icon" v-if="item.icon"/> {{ item.name }}
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons" v-if="!isLoggedin">
          <b-button type='is-light' outlined v-if="registrationEnabled"
                    tag="router-link"
                    to="/register"
                    icon-left="user-plus">
            Register
          </b-button>
          <b-button type='is-light' outlined
                    tag="router-link"
                    to="/login"
                    icon-left="user">
            Log in
          </b-button>
        </div>

        <b-navbar-dropdown v-else>
          <template slot="label">
            <b-icon class="pr-3" icon="user-circle"/> {{ userName }}
          </template>
          <b-navbar-item tag="router-link" to="/user">
            <b-icon class="pr-3" icon="user"/> User Profile
          </b-navbar-item>
          <b-navbar-item @click="logout">
            <b-icon class="pr-3" icon="sign-out-alt"/> Logout
          </b-navbar-item>
        </b-navbar-dropdown>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      pages: [],
      links: [],
      header: null,
    };
  },
  computed: {
    isLoggedin() {
      return this.$ctforge.state.loggedin;
    },
    userName() {
      return this.$ctforge.state.userinfo.name;
    },
    registrationEnabled() {
      return this.$ctforge.state.config.REGISTRATION_ENABLED;
    },
  },
  methods: {
    async logout() {
      await this.$ctforge.logout();
      this.notify('Logged Out');
      this.$router.replace('/');
    },
    async refresh() {
      this.header = (await this.$ctforge.api.get('/cms/page/__header__')).content;
      this.pages = await this.$ctforge.api.get('/cms/pages');
      this.links = this.$ctforge.state.topbar
        .filter((item) => !item.loginRequired || (item.loginRequired && this.isLoggedin))
        .concat(this.pages
          .filter((p) => p.route !== '__index__' && p.route !== '__header__' && !p.hidden
                            && (!p.login_required || (p.login_required && this.isLoggedin))
                            && (p.order))
          .map((p) => ({
            name: p.title,
            path: `/${p.route}`,
            icon: p.icon,
            order: p.order,
          }))
          .sort((a, b) => a.order > b.order));
    },
  },
  async mounted() {
    this.refresh();
  },
  watch: {
    '$ctforge.state.topbar': function () { this.refresh(); },
    isLoggedin() { this.refresh(); },
  },
};
</script>

<style>
</style>
