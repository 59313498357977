<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="edit" />
        Evaluation
      </p>
      <span class="card-header-icon" aria-label="back">
          <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
      </span>
    </header>
    <div class="card-content">
      <div class="content">
        <table class="table is-fullwidth is-bordered">
          <tbody>
            <tr>
              <td><b-icon class="pr-3" icon="user"/> User</td> <td> <b>{{ data.name }} {{ data.surname }} ({{ data.nickname }})</b> </td>
            </tr>
            <tr>
              <td width="160"><b-icon class="pr-3" icon="envelope"/> Mail</td> <td>{{ data.mail }}</td>
            </tr>
            <tr>
              <td width="160"><b-icon class="pr-3" icon="th-list"/> Challenge</td> <td>{{ data.challenge }}</td>
            </tr>
            <tr>
              <td><b-icon class="pr-3" icon="clock"/> Timestamp</td> <td> <b>{{ data.timestamp }}</b> </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>

    <WriteupInfo :id="this.id" :hideHeader="true" class="mt-5 mb-5" />

     <div class="card mt-5">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="star" />
              Grade Writeup
            </p>
          </header>
          <div class="card-content">
            <div class="content">

    <form @submit.prevent="submit">
      <b-field label="Rating">
        <b-input type="number" v-model="data.rating" step="any"></b-input>
      </b-field>
      <b-field label="Feedback">
        <b-input type="textarea" v-model="data.feedback"></b-input>
      </b-field>

      <b-button native-type="submit" class="is-primary">Save</b-button>
    </form>

            </div>
          </div>
     </div>

  </div>
</template>

<script>
import WriteupInfo from '../WriteupInfo.vue';

export default {
  name: 'AdminEvaluation',
  components: {
    WriteupInfo,
  },
  data() {
    return {
      id: this.$route.params.writeup_id,
      data: {},
    };
  },
  methods: {
    async submit() {
      try {
        let { rating } = this.data;
        if (rating === '') rating = null;
        const res = await this.$ctforge.api.post('/admin/evaluation/submit', {
          challenge_id: this.data.challenge_id,
          user_id: this.data.user_id,
          rating,
          feedback: this.data.feedback,
        });
        this.notify(res.message);
        this.$router.push('/admin/writeups');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  async mounted() {
    try {
      this.data = await this.$ctforge.api.get(`/admin/${this.id}/evaluation`);
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },

};
</script>

<style>
</style>
