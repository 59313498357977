<template>
<div class="columns">
  <div class="column is-one-third">
    <form @submit.prevent="login">
      <h1 class="pb-5 has-text-weight-bold is-size-3">Please sign in</h1>
      <b-field label="Email" label-position="on-border">
        <b-input ref="email" type="email" v-model='email' placeholder="nobody@nowhere.com" expanded required focus></b-input>
      </b-field>

      <b-field label="Password" label-position="on-border">
        <b-input ref="password" type="password" v-model='password' placeholder="password" expanded password-reveal required></b-input>
      </b-field>

      <b-field>
        <p class="">
          <b-button native-type="submit" class="is-primary">Login</b-button>
        </p>
        <!-- TODO: forgot password link -->
        <!--
        <p class=" pt-2 pl-4">
          <a>Forgot your password?</a>
        </p>
        -->
      </b-field>

      <slot name="buttons"></slot>
    </form>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    isLoggedin() {
      return this.$ctforge.loggedin;
    },
  },
  methods: {
    async login() {
      try {
        const res = await this.$ctforge.api.post('/users/login', {
          mail: this.email,
          password: this.password,
        });
        this.notify(res.message);
        await this.$ctforge.checkLogin();
        this.$router.push('/');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  mounted() {
    if (this.isLoggedin) this.$router.push('/');
  },
};
</script>

<style>
</style>
