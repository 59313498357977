<template>
<div>
   <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="clone" /> Plagiarism {{ data.challenge_name ? "- " + data.challenge_name : "" }}
      </p>
      <span class="card-header-icon" aria-label="back">
          <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
      </span>

    </header>

    <div class="card-content">
      <div class="content">

        <nav class="level">
          <div class="level-left">
            <div class="level-item">
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <b-field>
                <b-switch v-model="codeView" @input="loadChartData">
                  {{ codeView ? "Code Similarity" : "Text Similarity" }}
                </b-switch>
              </b-field>
            </div>
          </div>
        </nav>

        <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
        <div class="heatmap-chart" ref="chartdiv"></div>

      </div>
    </div>
   </div>

</div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesDark from '@amcharts/amcharts4/themes/amchartsdark';

am4core.useTheme(am4themesDark);

const colors = {
  critical: '#ca0101',
  bad: '#e17a2d',
  medium: '#e1d92d',
  good: '#5dbe24',
  verygood: '#0b7d03',
};

export default {
  data() {
    return {
      challengeId: this.$route.params.challenge_id,
      data: {},
      users: [],
      codeView: false,
      isLoading: false,
    };
  },
  methods: {
    setupChart() {
      const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
      chart.maskBullets = false;
      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      xAxis.dataFields.category = 'x';
      yAxis.dataFields.category = 'y';
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;
      xAxis.renderer.labels.template.rotation = -90;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 30;

      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = 'x';
      series.dataFields.categoryY = 'y';
      series.dataFields.value = 'value';
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      const column = series.columns.template;
      column.strokeWidth = 2;
      column.strokeOpacity = 1;
      column.stroke = am4core.color('#ffffff');
      column.tooltipText = "{x}, {y}: {value.workingValue.formatNumber('#.')}";
      column.width = am4core.percent(100);
      column.height = am4core.percent(100);
      // column.column.cornerRadius(6, 6, 6, 6);
      column.propertyFields.fill = 'color';

      const legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [{
        name: '< 25%',
        fill: am4core.color(colors.verygood),
      }, {
        name: '> %25',
        fill: am4core.color(colors.good),
      }, {
        name: '> 50%',
        fill: am4core.color(colors.medium),
      }, {
        name: '> 75%',
        fill: am4core.color(colors.bad),
      }, {
        name: '> 90%',
        fill: am4core.color(colors.critical),
      }];
      legend.itemContainers.template.togglable = false;

      this.chart = chart;
    },
    loadChartData() {
      const nameLookup = new Map(this.users.map((user) => [user.id, `${user.name} ${user.surname}`]));

      const prop = this.codeView ? this.data.code_sim : this.data.text_sim;

      const data = Object.entries(prop).flatMap(([u1Id, similarities]) => Object.entries(similarities).map(([u2Id, score]) => {
        let color;
        if (score < 0.25) {
          color = colors.verygood;
        } else if (score < 0.5) {
          color = colors.good;
        } else if (score < 0.75) {
          color = colors.medium;
        } else if (score < 0.9) {
          color = colors.bad;
        } else {
          color = colors.critical;
        }
        return {
          x: nameLookup.get(parseInt(u1Id, 10)) || u1Id,
          y: nameLookup.get(parseInt(u2Id, 10)) || u2Id,
          color,
          value: score * 100,
        };
      }));

      this.chart.data = data;
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      this.data = await this.$ctforge.api.get(`/admin/plagiarism/check_similarity/${this.challengeId}`);
      this.users = await this.$ctforge.api.get('/admin/users/list');
    } catch (e) {
      this.notify(e.message, 'is-danger');
      this.isLoading = false;
    }
    this.setupChart();
    this.loadChartData();
    this.isLoading = false;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style>
.heatmap-chart {
  width: 100%;
  height: 1000px;
}
</style>
