<template>
  <div>
    <h1 class="pb-5 has-text-weight-bold is-size-3">Scoreboard</h1>

    <div class="box has-text-centered is-size-4" v-if="!loading && !data.length">
      <b-icon icon="frown" class="mr-2" />
      No Data
    </div>
    <b-table v-else
      class="scoreboard"
      :data="data"
      hoverable :loading="loading"
      default-sort="position">
      <b-table-column
          field="position"
          label="#"
          header-class="valign-custom"
          numeric centered width='40'
          v-slot="props">
        {{ props.row.position }}
      </b-table-column>

      <!-- TODO: enable only if at least one user has affiliation != null -->
      <b-table-column
          field="affiliation"
          label="Course"
          header-class="valign-custom"
          width="80"
          v-slot="props">
        <b-icon icon="minus" v-if="!props.row.affiliation" />
        {{ props.row.affiliation }}
       </b-table-column>

      <b-table-column
          field="nickname"
          label="User"
          header-class="valign-custom"
          v-slot="props">
        <b-icon icon="minus" v-if="!props.row.nickname" />
        {{ props.row.nickname }}
      </b-table-column>

      <b-table-column
          v-for="(column, index) in headers"
          :key="index"
          field="points"
        :label="column.name"
        numeric centered>
        <template v-slot:default="props">
        <b-icon icon="times" v-if="props.row.challenges[column.name]['points']==0" />
        <span :title="new Date(props.row.challenges[column.name]['timestamp']).toLocaleString()" v-else>
          {{props.row.challenges[column.name]['points']}}
        </span>
        </template>
        <template v-slot:header>
          <b-button tag="router-link" :to="'/challenges/' + column.name" type="is-normal" class="scoreboard-button">{{ column.name }}</b-button>
        </template>
      </b-table-column>

      <b-table-column
          field="total_points"
          label="Leetscore"
          numeric centered width='40'
          header-class="pl-6 pr-5 valign-custom"
          v-slot="props">
        {{ props.row.total_points }}
      </b-table-column>
    </b-table>

    <div class="mt-5 scoreboard-chart" ref="chartdiv"></div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesDark from '@amcharts/amcharts4/themes/amchartsdark';

am4core.useTheme(am4themesDark);

export default {
  data() {
    return {
      loading: true,
      data: [],
      headers: [],
      interval: null,
    };
  },
  methods: {
    async update() {
      try {
        this.headers = (await this.$ctforge.api.get('/challenges/list')).filter((h) => h.active !== false);
        this.data = await this.$ctforge.api.get('/challenges/scoreboard');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      this.loading = false;
      this.setChartData();
    },
    setChartData() {
      const chalNames = this.headers.map((h) => h.name);
      const allEvents = this.data.flatMap((user) => Object.entries(user.challenges)
        .filter(([, value]) => value.timestamp !== null)
        .map(([chal, value]) => ({
          name: chal,
          date: new Date(value.timestamp),
          points: value.points,
        })));
      allEvents.sort((a, b) => a.date - b.date);

      const now = new Date();
      if (allEvents.length > 0) {
        const startDate = new Date(allEvents[0].date - 1000);
        allEvents.push(...chalNames.map((name) => ({
          name,
          date: startDate,
          points: 0,
        })));
        allEvents.sort((a, b) => a.date - b.date);
      }

      chalNames.forEach((name) => {
        let sum = -1; // start counting from 0 (see line 111)
        const seriesAccumulated = allEvents
          .filter((v) => v.name === name)
          .map((v) => {
            sum += 1;
            return {
              date: v.date,
              solvers: sum,
            };
          });
        const lastPoints = seriesAccumulated.length > 0 ? seriesAccumulated[seriesAccumulated.length - 1].solvers : 0;
        seriesAccumulated.push({
          date: now,
          solvers: lastPoints,
        });
        this.getOrCreateSeries(name).data = seriesAccumulated;
      });
    },
    getOrCreateSeries(name) {
      if (name in this.chartSeries) {
        return this.chartSeries[name];
      }
      const series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'solvers';
      series.legendSettings.labelText = name;

      series.tooltipText = `${name}: {valueY.value}`;
      this.chart.scrollbarX.series.push(series);
      this.chartSeries[name] = series;
      return series;
    },
    createChart() {
      const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      chart.paddingRight = 20;

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      valueAxis.title.text = 'Solvers';
      valueAxis.maxPrecision = 0;

      const scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();
      chart.legend = new am4charts.Legend();

      this.chartSeries = {};
      this.chart = chart;
    },
  },
  async mounted() {
    this.createChart();
    this.interval = setInterval(() => this.update(), 30000);
    // initial update
    await this.update();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
};

</script>

<style>
th {
  vertical-align:middle !important;
}
.scoreboard-chart {
  width: 100%;
  height: 500px;
}
.scoreboard-button {
    max-width: 7em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;

    border: 0;
    transform: rotate(-45deg);
    transform-origin: 16% 100%;
    display: inline-block;
    position: absolute;
}
.scoreboard thead {
    height: 7em;
}
.valign-custom {
    /*vertical-align: bottom !important;*/
}
</style>
