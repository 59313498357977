import Scoreboard from './components/Scoreboard.vue';
import Challenges from './components/Challenges.vue';
import ChallengeInfo from './components/ChallengeInfo.vue';
import AdminChallenges from './components/admin/AdminChallenges.vue';
import AdminChallengeForm from './components/admin/AdminChallengeForm.vue';

export default {

  install(vue) {
    vue.$router.addRoutes([
      {
        path: '/scoreboard', component: Scoreboard,
      },
      {
        path: '/challenges',
        component: Challenges,
        meta: { requiresLogin: true },
      },
      {
        path: '/challenges/:challenge_name',
        component: ChallengeInfo,
        meta: { requiresLogin: true },
      },
    ]);
    vue.$ctforge.addTopbarLinks([
      {
        name: 'Scoreboard', path: '/scoreboard', loginRequired: false, icon: 'chart-line',
      },
      {
        name: 'Challenges', path: '/challenges', loginRequired: true, icon: 'th-list',
      },
    ]);
    // Admin interface
    vue.$router.addChildrenRoutes('/admin', [
      {
        path: 'challenges',
        component: AdminChallenges,
        meta: { requiresAdmin: true },
      },
      {
        name: 'admin_challenges_add',
        path: 'challenges/add',
        component: AdminChallengeForm,
        meta: { requiresAdmin: true },
        props: { isEditing: false },
      },
      {
        name: 'admin_challenges_edit',
        path: 'challenges/edit/:challenge_name',
        component: AdminChallengeForm,
        meta: { requiresAdmin: true },
        props: (route) => ({
          isEditing: true,
          challengeName: route.params.challenge_name,
        }),
      },
    ]);
    vue.$ctforge.addAdminLinks([
      { name: 'Challenges', path: '/admin/challenges', icon: 'th-list' },
    ]);
  },

};
