<template>
<div>
  <h1 class="pb-5 has-text-weight-bold is-size-3">User Profile</h1>

  <div class="columns">
    <div class="column is-two-thirds">
      <table class="table is-fullwidth is-bordered">
        <tbody>
          <tr>
            <td><b-icon class="pr-3" icon="user"/> Name</td> <td> {{ userinfo.name }} {{userinfo.surname}}
              {{ userinfo.nickname ? `(${userinfo.nickname})` : '' }} </td>
          </tr>
          <tr>
            <td><b-icon class="pr-3" icon="envelope"/> Mail</td> <td> {{ userinfo.mail }} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column">
      <b-button icon-left="download" expanded v-for="file in files" :key="file.hash" tag="a" :href="`/cms/files/${file.hash}/${file.name}`">{{ file.name }}</b-button>
    </div>
  </div>
  <slot name="userdata"></slot>
</div>
</template>

<script>
export default {
  data() {
    return {
      files: [],
    };
  },
  computed: {
    userinfo() {
      return this.$ctforge.state.userinfo;
    },
  },
  async mounted() {
    const loadingComponent = this.$buefy.loading.open({ container: null });
    try {
      this.files = await this.$ctforge.api.get('/user/files');
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
    loadingComponent.close();
  },
};
</script>

<style>
</style>
